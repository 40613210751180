.container {
    width: 100%;
    height: 85px;
    background-color: none;
    border-bottom: 2px solid #1e292e;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 40px;
    font-size: 1.2rem;
}