@import '../../style/colors';

.container {
    padding: 97px 43px 20px 43px;

    @media screen and ( max-width: 767px) {
        padding: 30px 10px;
    }
}

.detailbox {
    width: 100%;
    min-height: 240px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 54px 36px;
    border: 1px solid wheat;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and ( max-width: 767px) {
        padding: 20px 10px;

        &_title {
            font-size: 14px;
        }
    }

    &_title {
        display: block;
        width: 150px;
    }

    &_total_amount_wrapper {
        width: 200px;
        height: 200px;
        border: 25px solid $color-golden;
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }

    &_table_wrapper {
        width: 100%;
        padding-top: 0.75rem;

        table {
            width: 100%;
            font-size: 14px;
            
            thead {
                height: 55px;
                border-radius: 20px;
                color: $color-light;
                text-align: center;

                tr {
                    background-image: linear-gradient(to bottom, #04151c, #04151c);
                }
            }

            tr {
                width: 100%;
                height: 50px;

                &:nth-child(odd) {
                    background-color: #16272F;
                }

                &:nth-child(even) {
                    background-color: none;
                }
            
                td {
                    color: $color-light;
                    text-align: center;
                }    
            }
        }   
    }

    select {
        height: 43px;
        border-radius: 28px;
        color: #ffffff;
        background-color:  $color-ever-green;
        font-size: 14px;
    }
}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.button {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;
}
