@import '../../style/colors';

.container {
    padding: 97px 43px 20px 43px;

    @media screen and ( max-width: 767px ) {
        padding: 30px 10px;
    }
}

.detailbox {
    width: 100%;
    min-height: 200px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 54px 36px;
    border: 1px solid wheat;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and ( max-width: 767px ) {
        padding: 20px 15px;
    }

    &_title {
        display: block;
        width: 165px;
    }

    select {
        height: 43px;
        border-radius: 28px;
        color: #ffffff;
        background-color:  $color-ever-green;
        scrollbar-width: thin;
        margin-top: 5px;
    }
}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.button {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;
}

.card_wrapper {
    max-width: calc(100vw - 365px);
    display: flex;
    padding: 2rem 0;
    overflow-x: auto; 
    height: 250px;
    scrollbar-width: thin;
    margin-bottom: 40px;
    box-shadow:  64px 0px 44px -17px rgba(0,0,0,0.3) inset; 
    -webkit-box-shadow: 64px 0px 44px -17px rgba(0,0,0,0.3) inset;
    -moz-box-shadow: 64px 0px 44px -17px rgba(0,0,0,0.3) inset;   
     
    @media screen and ( max-width: 767px ) {
        max-width: calc(100vw - 60px);
    }
}

.card {
    display: block;
    width: 340px;
    height: 200px;
    border-radius: 20px;
    border: solid 2px $color-teal;
    background-color: #061218;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        width: 110px;
        height: 90px;
        margin-bottom: 1rem;
    }
    span {
        font-size: 1.3rem;
        letter-spacing: 0.3px;
        color: #ffffff;
    }
}

.card_wallets {
    width: 340px;
    height: 200px;
    border-radius: 20px;
    border: solid 2px $color-teal;
    background-color: #061218;
    cursor: pointer;
    padding: 20px;
    margin: 0 30px;

    &_titleWrapper {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid gray;

        span {
            font-size: 1.3rem;
            margin-right: 15px;
        }
    }

    &_iconWrapper {
        text-align: center;
        font-size: 1.2rem;
    }
}

.register_btn {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;
}