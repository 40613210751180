@import './typography';
@import './colors';

body, html {
    @include typography;
    background-color: #1a1b1e;
    background-image: $background-gradient;
    background-repeat: no-repeat;
    background-attachment: fixed;   
    background-size: cover;
    height: 100% !important;
    text-align: right;
    margin: 0 !important;
    padding: 0 !important;
}

a {
    color: $color-cool-blue;

    &:hover {
        color: $color-pinkish-gray;
    }
}

.text-pinkish-gray {
    color: $color-pinkish-gray;
}

.text-cool-blue {
    color: $color-cool-blue;
}

.text-ever-green {
    color: $color-ever-green;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-nav-tabs {
    padding: 0 !important;
    border-bottom: 1px solid $color-cool-blue !important;

    .nav-link.active {
        background-color: $color-ever-green !important;
        color: $color-light !important;
        border-color: $color-cool-blue !important;
        border-bottom: 4px solid $color-ever-green !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    
        &:hover {
            border-color: $color-cool-blue !important;
            border-bottom: 4px solid $color-ever-green !important;
        }
    }
}

.custom-nav-item {
    background-color: #39454f;
    margin-left: 0.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &:last-child {
        margin-left: 0;
    }
    
    .nav-link {
        color: rgb(129, 129, 129);

        &:hover {
            border-color: $color-cool-blue !important;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;        
        }
    }
}

.custom-tab-content {
    border: 1px solid $color-cool-blue !important;
    border-top: none !important;
}