@import '../../style/colors';

.container {
    padding: 97px 43px 20px 43px;

    @media screen and (max-width: 766px) {
        padding: 20px;
    }
}

.detailbox {
    width: 100%;
    min-height: 140px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 54px 36px;
    border: 1px solid wheat;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and (max-width: 766px) {
        padding: 15px 10px 0 10px;
    }

    &_title {
        display: block;
        width: 130px;
    }
}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.button {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;

    &:disabled {
        background-color: $color-pinkish-gray;
        cursor: not-allowed;
    }
}

.otp_button {
    font-size: 1rem;
    width: 208px;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;
}

.two_factor_form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;

    &_input {
        width: 260px;
        background-color: aliceblue;
        text-align: center;
        color: #000405;
    }

    &_btn {
        font-size: 1rem;
        height: 45px;
        border-radius: 1.75rem;
        color: $color-light;
        background-color: $color-cool-blue;
        width: 260px;
    }
}