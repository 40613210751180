@font-face {
    font-family: "YekanBakh";
    src: url("../assets/fonts/yekanbakh/7296ae79f6faca68798b00fbbbee4c22.eot"); /* IE9*/
    src: url("../assets/fonts/yekanbakh/7296ae79f6faca68798b00fbbbee4c22.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../assets/fonts/yekanbakh/7296ae79f6faca68798b00fbbbee4c22.woff2") format("woff2"), /* chrome、firefox */
    url("../assets/fonts/yekanbakh/7296ae79f6faca68798b00fbbbee4c22.woff") format("woff"), /* chrome、firefox */
    url("../assets/fonts/yekanbakh/7296ae79f6faca68798b00fbbbee4c22.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../assets/fonts/yekanbakh/7296ae79f6faca68798b00fbbbee4c22.svg#YekanBakh-Regular") format("svg"); /* iOS 4.1- */
  }
  
@mixin typography {
    font-family: YekanBakh;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.16px;
}