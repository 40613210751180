@import '../../style/colors';

.container {
    padding: 97px 43px 20px 43px;
    // position: relative;

    .edit_modal {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: #6a757a;
        // width: 400px;
        padding: 16px;
        // position: absolute;
        // border-radius: 8px;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
        }

        &_field {
            display: flex;
            flex-direction: column;
            gap: 2px;

            input {
                border: 1px solid;
                text-align: center;
                width: 100%;
                height: 45px;
                border-radius: 1.75rem;
                background-color: $color-ever-green;
                color: $color-pinkish-gray;

                &:focus {
                    outline: none;
                    box-shadow: 0px 0px 6px $color-teal;
                }

                &:disabled {
                    background-color: #797676;
                    cursor: not-allowed;
                }
            }
        }

        &_btn {
            width: 120px;
            margin: 0 auto;
            height: 45px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                cursor: not-allowed;
            }
        }

        &_get_amount {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &_btn {
                width: 160px;
                height: 32px;
                font-size: 0.75rem;
                border-radius: 16px;
            }

            span {
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 30px 10px;
    }
}

.detailbox {
    width: 100%;
    min-height: 336px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 54px 36px;
    border: 1px solid wheat;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and (max-width: 767px) {
        padding: 20px 10px;
    }

    &_title {
        display: block;
        width: 90px;
    }

    &_action {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            height: 38px;
            background-color: #072531;
            color: #ffffff;
            font-size: 1rem;
            border-radius: 28px;
            padding: 0.4rem 1rem;

            b {
                font-size: 1.6rem;
                color: #3ea9c2;
            }

            &:hover {
                background-color: #2d3437;
            }
        }
    }

    &_total_amount_wrapper {
        width: 200px;
        height: 200px;
        border: 25px solid $color-golden;
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }

    &_table_wrapper {
        width: 100%;
        padding-top: 0.75rem;

        table {
            width: 100%;
            font-size: 14px;

            thead {
                height: 55px;
                border-radius: 20px;
                color: $color-light;
                text-align: center;

                tr {
                    background-image: linear-gradient(to bottom, #04151c, #04151c);
                }
            }

            tr {
                width: 100%;
                height: 50px;

                &:nth-child(odd) {
                    background-color: #16272F;
                }

                &:nth-child(even) {
                    background-color: none;
                }

                td {
                    color: $color-light;
                    text-align: center;
                }
            }
        }
    }

    select {
        height: 43px;
        border-radius: 28px;
        color: gray;
        background-color: $color-ever-green;
    }
}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.button {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;
}