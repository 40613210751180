$color-deep-sky-blue: #0481ff;
$color-pinkish-gray: #cfcfcf;
$color-cool-blue: #3ea9c2;
$color-ever-green: #072531;
$color-light: #ffffff;
$color-golden: #e8b900;
$color-teal: #00a76f;
$background-gradient: radial-gradient(at 36% 22%, hsla(0,0%,0%,1) 0, transparent 50%),
radial-gradient(at 80% 0%, hsla(0,0%,0%,1) 0, transparent 50%),
radial-gradient(at 0% 50%, hsla(0,0%,0%,1) 0, transparent 65%),
radial-gradient(at 0% 100%, hsla(0,0%,0%,1) 0, transparent 95%),
radial-gradient(at 80% 50%, hsla(0,0%,0%,1) 0, transparent 50%),
radial-gradient(at 100% 100%, hsla(206,100%,51%,1) 0, transparent 0%),
radial-gradient(at 43% 1%, hsla(201,100%,50%,1) 0, transparent 77%);
