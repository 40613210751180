@import '../../style/colors';

.container {
    padding: 45px 43px 20px 43px;

    @media screen and ( max-width: 767px) {
        padding: 30px 10px;
    }
}

.detailbox {
    width: 100%;
    min-height: 240px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 35px;
    border: 1px solid wheat;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and ( max-width: 766px ) {
        padding: 20px 10px;
    }

    &_title {
        display: block;
        width: 130px;
    }

    &_table_wrapper {
        width: 100%;
        padding: 1rem 0;

        table {
            width: 100%;
            
            thead {
                height: 55px;
                border-radius: 20px;
                color: $color-light;
                text-align: center;

                tr {
                    background-image: linear-gradient(to bottom, #04151c, #04151c);
                }
            }

            tr {
                width: 100%;
                height: 50px;

                &:nth-child(odd) {
                    background-color: #16272F;
                }

                &:nth-child(even) {
                    background-color: none;
                }
            
                td {
                    color: $color-light;
                    text-align: center;
                }    
            }
        }   
    }
}

.statsWrapper {
    max-width: 90%;
    margin: 0 auto;
    border-top: 1px solid #707070;
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-top: 39px;

    .statsBlock {
        width: 280px;
        height: 84px;
        border-left: 1px solid #707070;
        text-align: center;

         &:last-child {
             border-left: none;
         }

         .blockData {
            font-size: 21px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing:  0.36px;
            color: $color-light;
         }

         .blockTitle {
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.53;
            letter-spacing: 0.19px;
            color: $color-pinkish-gray;
         }
    }
    
    @media screen and ( max-width: 766px ) {
        flex-flow: column;
        align-items: center;

        .statsBlock {
            border-left: none;
            border-bottom:  1px solid #707070;
            padding-top: 12px;

            &:last-child {
                border-bottom: none;
            }   
        }
    }
}

.interval {
    margin-top: 78px;

    &_title {
        width: 240px;
    }

    table {
        width: 100%;
        
        thead {
            height: 55px;
            color: $color-light;
            text-align: center;
            border-bottom: 1px solid #f5f5f5;
        }

        tr {
            width: 100%;
            height: 50px;
            border-bottom: 1px solid #f5f5f5;

            th {
                font-weight: normal !important;
            }

            td {
                color: $color-light;
                text-align: center;
            }    
        }
    }

    @media screen and ( max-width: 766px ) {
        &_title {
            font-size: 12px;
        }
    
        th {
            font-size: 13px;
            font-weight: 400 !important;
        }    

        td {
            font-size: 12px;
            font-weight: 300 !important;
        }
    }
}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.button {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;
}
