@import '../../style/colors';

.container {
    height: 100vh;
    max-width: 1920px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    margin: 0 auto;

    @media screen and ( max-width: 1100px ){
        flex-direction: column;
        justify-content: unset;
    }
}

.form_wrapper {
    max-width: 400px;
    text-align: center;
}

.chain_pic {
    max-width: 600px;
    height: 450px;

    @media screen and ( max-width: 1100px ) {
        display: none;
    }

    &_mobile {
        max-width: 480px;
        height: 350px;
        margin: 1.5rem 0;
        
        @media screen and ( min-width: 1100px ) {
            display: none;
        }
    }
}

.login_title {
    color: $color-light;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.24px;
    padding-bottom: 0.75rem;
    margin: 31px 5px 21px 5px;
    border-bottom: 1px solid $color-light;
}

.button {
    font-size: 1.5rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 1rem;
    
    &:disabled {
        background-color: $color-pinkish-gray;
        cursor: not-allowed;
    }
}

.links_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}