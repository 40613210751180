.container {
    width: 187px;
    height: 100vh;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);
    position: sticky;
    top: 0;
    border-left: 2px solid #0094c6;
    padding-right: 5px;

    @media screen and ( max-width: 767px ) {
        display: none;
    }

    &_mobile {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #cfcfcf;
        border-bottom: 1px solid #0094c6;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:hover {
               transform: scale(1.2,1.2);
            }
        }
    }
}

.item {
    height: 60px;
    text-align: center;
    cursor: pointer;
    padding-top: 15px;
    color: #ffffff;

    &:hover {
        color: #0094c6;
    }

    i {
        float: right;
        margin-right: 15px;
    }
}

.selected_item {
    height: 60px;
    border: 2px solid #0094c6;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    border-left: none;
    background-color: #040e10;
    text-align: center;
    color: #0094c6;
    padding-top: 15px;
    z-index: 3;
    margin-left: -2px;
    cursor: pointer;

    i {
        float: right;
        margin-right: 15px;
    }
}

.mobile_item {
    cursor: pointer;

    &_selected {
        margin-bottom: -4px;
        color: #0094c6;
        background-color: #051014;
        padding: 0.5rem 0.75rem 0.15rem 0.75rem;
        border: 1px solid #0094c6;
        border-top-right-radius: 28px;
        border-top-left-radius: 28px;
        border-bottom: none;
        z-index: 2;
    }
}

.exit_container {
    margin-top: 20px;

    img {
        cursor: pointer;

        &:hover {
            margin-right: 5px;
        }
    }
}