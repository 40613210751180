@import '../../style/colors';

.container {
    padding: 97px 43px 20px 43px;

    @media screen and ( max-width: 766px ) {
        padding: 30px 10px;
    }
}

.input {
    max-width: 400px;
}

.detailbox {
    width: 100%;
    min-height: 336px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 30px 36px;
    border: 1px solid #ffffff;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and ( max-width: 766px ) {
        padding: 20px 7px;
    }

    &_title {
        display: block;
        width: 120px;
    }

    &_table_wrapper {
        width: 100%;
        margin-bottom: 2rem;

        table {
            width: 100%;

            @media screen and ( max-width: 767px ) {
                font-size: 14px;
            }
            
            thead {
                height: 55px;
                border-radius: 20px;
                color: $color-light;
                text-align: center;

                tr {
                    background-image: linear-gradient(to bottom, #04151c, #04151c);
                }
            }

            tr {
                width: 100%;
                height: 50px;

                &:nth-child(odd) {
                    background-color: #16272F;
                }

                &:nth-child(even) {
                    background-color: none;
                }

                td {
                    color: $color-light;
                    text-align: center;

                    img {
                        width: 30px;
                        height: 30px;
                        padding: 0 5px;
                        cursor: pointer;
                        margin-right: 7px;
                        border-bottom: 1px solid gray;

                        @media screen and ( min-width: 767px ) {                            
                            border-left: 1px solid gray;
                            border-bottom: none;
                            margin-right: 0;
                        }
                    }
                }    
            }
        }   
    }
}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.addmember_btn {
    height: 45px;
    background-color: #072531;
    color: #ffffff;
    font-size: 1.2rem;
    border-radius: 28px;
    padding: 0.4rem 1rem;

    img {
        width: 20px;
        height: 20px;
    }

    &:hover {
        background-color: #2d3437;
    }
}

.addmember_formbox {
    width: 100%;
    min-height: 200px;
    padding: 15px;
    background-color: #0e191e;
    border-radius: 28px;
}

.register_btn {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;

    &:disabled {
        background-color: $color-pinkish-gray;
        cursor: not-allowed;
    }
}

// .detailModalQuickInfoTitle {
//     // border-bottom: 1px solid #707070;
// }

.interval {
    margin-top: 45px;

    &_title {
        width: 240px;
    }

    table {
        width: 100%;
        
        thead {
            height: 55px;
            color: #1c242f;
            text-align: center;
            border-bottom: 1px solid #707070;
        }

        tr {
            width: 100%;
            height: 50px;
            border-bottom: 1px solid #707070;

            th {
                font-weight: normal !important;
            }

            td {
                color: #1c242f;
                text-align: center;
            }    
        }
    }

    @media screen and ( max-width: 766px ) {
        &_title {
            font-size: 12px;
        }
    
        th {
            font-size: 13px;
            font-weight: 400 !important;
        }    

        td {
            font-size: 12px;
            font-weight: 300 !important;
        }
    }
}

.display_none {
    display: none;
}