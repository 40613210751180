@import '../../style/colors';

.container {
    padding: 97px 43px 20px 43px;

    @media screen and ( max-width: 767px ) {
        padding: 30px 10px;
    }
}

.detailbox {
    width: 100%;
    min-height: 336px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 54px 36px;
    border: 1px solid wheat;
    color: $color-light;
    background-image: linear-gradient(to right, rgba(9, 24, 32, 0.6) 1%, rgba(9, 24, 32, 0.6) 102%);

    @media screen and ( max-width: 767px ) {
        padding: 20px 15px;
    }

    &_title {
        display: block;
        width: 180px;
    }

    select {
        font-size: 14px;
        height: 43px;
        border-radius: 28px;
        color: gray;
        background-color:  $color-ever-green;
        scrollbar-width: thin;
    }

    @media screen and ( max-width: 767px ) {
        ul {
            font-size: 12px;
        }
    }

}

.hr_line {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid #000405;
}

.button {
    font-size: 1.2rem;
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    color: $color-light;
    background-color: $color-cool-blue;
    margin-top: 0.2rem;

    &_buy {
        font-size: 1.2rem;
        width: 100%;
        height: 45px;
        border-radius: 1.75rem;
        color: $color-light;
        background-color: #00a76f;
        margin-top: 0.2rem;    
    }

    &_sell {
        font-size: 1.2rem;
        width: 100%;
        height: 45px;
        border-radius: 1.75rem;
        color: $color-light;
        background-color: rgba(244, 67, 54, 0.8);
        margin-top: 0.2rem;    
    }
}

.card_wrapper {
    max-width: calc(100vw - 365px);
    display: flex;
    padding: 2rem 0;
    overflow-x: auto;
    height: 250px;
    scrollbar-width: thin;

    box-shadow:  64px 0px 44px -17px rgba(0,0,0,0.3) inset; 
    -webkit-box-shadow: 64px 0px 44px -17px rgba(0,0,0,0.3) inset;
    -moz-box-shadow: 64px 0px 44px -17px rgba(0,0,0,0.3) inset;   
     
    @media screen and ( max-width: 767px ) {
        max-width: calc(100vw - 60px);
    }
}

.card_wallets {
    width: 340px;
    height: 200px;
    border-radius: 20px;
    border: solid 2px $color-teal;
    background-color: #061218;
    cursor: pointer;
    padding: 20px;
    margin: 0 30px;

    &_titleWrapper {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid gray;

        span {
            font-size: 1.3rem;
            margin-right: 15px;
        }
    }

    &_iconWrapper {
        display: flex;

        span {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 500;
            padding-top: 10px;
        }
    }
}

.card_wallets_selected {
    width: 340px;
    height: 200px;
    border-radius: 20px;
    border: dashed 2px gray;
    background-color: #102733;
    opacity: 0.8;
    cursor: pointer;
    padding: 20px;
    margin: 0 30px;

    &_titleWrapper {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid gray;

        span {
            font-size: 1.3rem;
            margin-right: 15px;
        }
    }

    &_iconWrapper {
        display: flex;

        span {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 500;
            padding-top: 10px;
        }
    }
}

.currencybox {
    width: 330px;
    height: 200px;
    border-radius: 20px;
    border: solid 2px $color-teal;
    background-color: #061218;
    cursor: pointer;
    padding: 20px;

    img {
        width: 70px;
        height: 70px;
    }

    &_title {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 500;
            padding-top: 10px;
    }
}

.deactive_currencybox {
    width: 330px;
    height: 200px;
    border-radius: 20px;
    border: dashed 2px gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.currency_select_container {
    position: relative;
    width: 100%;

    input {
        display: block !important;
        width: 100%;
        height: 45px;
        text-align: center;
        padding: 7px 10px 0 10px;
        border-radius: 1.72rem;
        border-color: $color-teal;
        border-width: 3px;

        &:focus {
            outline:none;
            box-shadow: 0px 0px 6px $color-teal;
        }    
    }

    ul {
        list-style-type: none;
        padding-left: 10px;
        text-align: end;
        margin: 0;
    }

    li {
        cursor: pointer;

        &:first-child {
            padding-top: 10px;
        }

        &:last-child {
            padding-bottom: 10px;
        }

        &:hover {
            font-weight: 900;
            padding-left: 10px;
        }
    }

    .currency_select_list {
        background-color: $color-pinkish-gray;
        color: #102733;
        display: block !important;
        position: absolute;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
    }
}