@import '../../style/colors';

.input {
    width: 100%;
    height: 45px;
    border-radius: 1.75rem;
    padding: 15.3px 31px 15.5px 23.1px;
    background-color: $color-ever-green;
    color: $color-pinkish-gray;
    margin: 5px 0;

    &:focus {
        outline:none;
        box-shadow: 0px 0px 6px $color-teal;
    }
}